/* eslint-disable */
import React from 'react';
import styles from './Contact-smart.module.css';

function ContactSmartComponent() {
  return (
    <>
      <div className={styles.container}>
        <section className={styles.contactPage}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className={styles.contactForm}>
                  <h3>CONTACTEZ-NOUS</h3>
                  <h6>Vous avez besoin d’aide ? N’hésitez pas à nous contacter !</h6>
                  <div className={styles.cform}>
                    <div className="row">
                      <div className="input-group col-md-6 mb-4">
                        <input
                          className="form-control bg-white border-left-0 border-md p-2"
                          type="text"
                          placeholder="Votre Nom"
                        />
                      </div>

                      <div className="input-group col-md-6 mb-4">
                        <input
                          className="form-control bg-white border-left-0 border-md p-2"
                          type="text"
                          placeholder="Votre Email"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="input-group col-md-6 mb-4">
                        <input
                          className="form-control bg-white border-left-0 border-md"
                          type="text"
                          placeholder="Sujet"
                        />{' '}
                      </div>
                    </div>

                    <div className="input-group col-md-12 mb-4">
                      <textarea
                        className="form-control bg-white border-left-0 border-md"
                        placeholder="Votre Message"
                      ></textarea>
                    </div>

                    <div className={styles.full}>
                      <input type="submit" value="Envoyer" className={styles.fsubmit} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className={styles.googleMap}>
                  <iframe
                    width="100%"
                    height="400"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Lot%20Essafi,%20Imm%20N%201%20Berrechid%2026100+(groupe%20ipirnet%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  >
                    <a href="https://www.gps.ie/">gps vehicle tracker</a>
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.contactInfo} container`}>
            <div className="row">
              <div className="col-md-12">
                <h3>Contact Information</h3>
              </div>
              {/* Contact Info Start */}
              <div className="col-md-4">
                <div className={styles.cInfo}>
                  <h6>Adresse:</h6>
                  <p>Lot Essafi, Imm N 1, 2eme Etage, Boulevard Hassan II, Berrchid 26100</p>
                </div>
              </div>
              {/* Contact Info End */}
              {/* Contact Info Start */}
              <div className="col-md-4">
                <div className={styles.cInfo}>
                  <h6>Contact:</h6>
                  <p>
                    <strong>Phone:</strong> +212 5223-27213
                  </p>
                </div>
              </div>
              {/* Contact Info End */}
              {/* Contact Info Start */}
              <div className="col-md-4">
                <div className={styles.cInfo}>
                  <h6>Pour plus d'information:</h6>
                  <p>
                    <strong>Email:</strong> info@groupeIpirnet.com
                  </p>
                  <p>contact@groupeIpirnet.com</p>
                </div>
              </div>
              {/* Contact Info End */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactSmartComponent;
