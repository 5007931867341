import React from 'react';
import styles from './About-smart.module.css';
import team from '../../assets/team.png';
import president from '../../assets/president.png';
import excellenceAcadémique from '../../assets/icons/excellence.png';
import vieEstudiantine from '../../assets/icons/estudiantine.png';
import ouvertureInternational from '../../assets/icons/international.png';
import rechercheInnovation from '../../assets/icons/innovation.png';

function AboutSmartComponent() {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-5">
            <div className="mt-4 bg-blanc">
              <p style={{ color: '#fff' }}>test</p>
            </div>
          </div>
          <div className="col-md-5">
            <div className="mt-4">
              <p style={{ color: '#fff' }}>test</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.home}>
        {/* section image header */}
        <div className={styles.homeImage}>
          <div className={styles.content}>
            <h1>Groupe IPIRNET</h1>
          </div>
        </div>

        <div className={styles.container}>
          {/* Section Mot du President */}
          <div className={styles.section}>
            <img src={president} alt="Président" className={styles.image} />
            <div className={styles.textBlock}>
              <h2 className={styles.title}>Mot du Président</h2>
              <p className={styles.paragraph}>
                Bienvenue sur le site du Groupe IPIRNET ! Nous sommes ravis de vous présenter notre
                institution, dédiée à l'excellence et à l'innovation dans le domaine de la
                formation. Chez IPIRNET, nous nous engageons à fournir une éducation de qualité,
                adaptée aux besoins actuels du marché, pour préparer nos étudiants à réussir dans
                leurs carrières. Nous croyons fermement que chaque individu possède un potentiel
                unique, et nous sommes ici pour les aider à le réaliser.
              </p>
            </div>
          </div>

          {/* Section Groupe Ipirnet avec background #ddd */}
          <div className={`${styles.section2} ${styles.backgroundGrey}`}>
            <div className={styles.textBlock}>
              <h2 className={styles.title}>Groupe Ipirnet</h2>
              <p className={styles.paragraph}>
                Nous offrons une gamme complète de programmes de formation conçus pour répondre aux
                besoins actuels du marché du travail et pour aider nos étudiants à atteindre leurs
                objectifs professionnels. Avec une équipe de formateurs expérimentés et passionnés,
                nous nous engageons à fournir une éducation de qualité et à encourager le
                développement personnel de chaque étudiant. Rejoignez-nous pour découvrir une
                approche innovante et enrichissante de la formation.
              </p>
            </div>
            <img src={team} alt="Groupe Ipirnet" className={styles.image} />
          </div>

          <div className={styles.valuesSection}>
            <h2 className={styles.valuesTitle}>Nos Valeurs</h2>
            <div className={styles.valuesContainer}>
              <div className={styles.valueCard}>
                <img
                  src={excellenceAcadémique}
                  alt="Excellence Académique"
                  className={styles.valueIcon}
                />
                <h3 className={styles.valueTitle}>Excellence Académique</h3>
              </div>
              <div className={styles.valueCard}>
                <img src={vieEstudiantine} alt="Vie Estudiantine" className={styles.valueIcon} />
                <h3 className={styles.valueTitle}>Vie Estudiantine</h3>
              </div>
              <div className={styles.valueCard}>
                <img
                  src={ouvertureInternational}
                  alt="Ouverture À L'International"
                  className={styles.valueIcon}
                />
                <h3 className={styles.valueTitle}>Ouverture À L'International</h3>
              </div>
              <div className={styles.valueCard}>
                <img
                  src={rechercheInnovation}
                  alt="Recherche Et Innovation"
                  className={styles.valueIcon}
                />
                <h3 className={styles.valueTitle}>Recherche Et Innovation</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSmartComponent;
