/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styles from './Formations-smart.module.css';

import managementImage from '../../assets/formations/mge.jpg';
import ressourcesHumainesImage from '../../assets/formations/rh.jpg';
import logistiqueImage from '../../assets/formations/logistique.jpg';
import commerceMarketingImage from '../../assets/formations/cm.jpg';
import genieLogicielImage from '../../assets/formations/gl.jpg';
import miageImage from '../../assets/formations/miage.jpg';

function FormationsComponent() {
  const branches = {
    managementEtRH: {
      id: 'managementEtRH',
      name: 'Management et RH',
      modules: [
        {
          name: 'Management Et Gestion Des Entreprises',
          img: managementImage,
          url: '/management-et-gestion-des-entreprises',
        },
        {
          name: 'Gestion Des Ressources Humaines',
          url: '/gestion-des-ressources-humaines',
          img: ressourcesHumainesImage,
        },
      ],
    },
    logistique: {
      id: 'logistique',
      name: 'Logistique',
      modules: [
        {
          name: "Responsable D'unité Opérationnelle Logistique",
          url: '/responsable-unite-operationnelle-logistique',
          img: logistiqueImage,
        },
      ],
    },
    commerceMarketing: {
      id: 'commerceMarketing',
      name: 'Commerce et Marketing',
      modules: [
        { name: 'Commerce Marketing', url: '/commerce-marketing', img: commerceMarketingImage },
      ],
    },
    informatique: {
      id: 'informatique',
      name: 'Informatique',
      modules: [
        {
          name: 'Ingénierie en Logiciel',
          url: '/ingenierie-en-logiciel',
          img: genieLogicielImage,
        },
        {
          name: "Méthode Informatique Appliquée pour la Gestion d'Entreprise",
          url: '/methode-informatique-appliquee',
          img: miageImage,
        },
      ],
    },
  };

  const [activeTab, setActiveTab] = useState('managementEtRH');

  // Effect to handle URL hash and set the active tab
  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (Object.keys(branches).includes(hash)) {
      setActiveTab(hash);
    }
  }, []);

  return (
    <>
      <div className="col-md-6">
        <p>test</p>
      </div>
      <div className="col-md-6">
        <p>test</p>
      </div>
      <div className="col-md-6">
        <p>test</p>
      </div>
      <div className={styles.title}>
        <h2>Licences Professionnelles</h2>
      </div>

      <div className={styles.licencesProfessionnelles}>
        <div className={styles.tabTitles}>
          {Object.keys(branches).map((branchKey, index) => (
            <button
              type="button"
              key={index}
              className={`${styles.tabButton} ${activeTab === branchKey ? styles.active : ''}`}
              onClick={() => setActiveTab(branchKey)}
            >
              {branches[branchKey].name}
            </button>
          ))}
        </div>
        {Object.keys(branches).map(
          (branchKey, index) =>
            activeTab === branchKey && (
              <div key={index} className={`${styles.cards} row`}>
                {branches[branchKey].modules.map((module, idx) => (
                  <div key={idx} className="col-md-4">
                    <img src={module.img} className={styles.image} alt={module.name} />
                    <h3 className={styles.title}>{module.name}</h3>
                    <a href={`/module/${module.name.replace(/\s+/g, '-')}`}>En savoir plus</a>
                  </div>
                ))}
              </div>
            ),
        )}
      </div>
    </>
  );
}

export default FormationsComponent;
