/* eslint-disable */
import React, { useState } from 'react';
import styles from './SignUp-smart.module.css';
import countriesData from '../../assets/phone.json';

function SignUpSmartComponent() {
  const [selectedCode, setSelectedCode] = useState('');

  const handleCountryChange = (event) => {
    setSelectedCode(event.target.value);
  };

  // Sort countries alphabetically by name
  const sortedCountries = countriesData.countries.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.par}></div>
        <div className={`${styles.all} row"`}>
          {/* <!-- For Demo Purpose --> */}
          <div className={`${styles.backgroundImage} col-md-5 pr-lg-5 mb-md-0"`}>
            <img
              src="https://res.cloudinary.com/mhmd/image/upload/v1569543678/form_d9sh6m.svg"
              alt=""
              className="img-fluid mb-3 d-none d-md-block"
            />
            <div className={styles.description}>
              <h5 className="font-italic mb-0">VOTRE</h5>
              <h2 className="font-italic mb-0">AVENIR</h2>
              <h5 className="font-italic mb-0">COMMENCE CHEZ</h5>
              <h2 className="font-italic mb-0">IPIRNET !</h2>
            </div>
          </div>

          {/* <!-- Registration Form --> */}
          <div className={`${styles.signup} col-md-7 col-lg-6 ml-auto"`}>
            <p>Année Universitaire 2024/2025</p>
            <h3>
              <span>
                <p style={{ color: '#000', marginRight: '1%' }}>INSCRIPTIONS</p>
              </span>
              <span>
                <p style={{ color: '#2c99c5' }}>OUVERTES !</p>
              </span>
            </h3>
            <form action="#">
              <div className={`${styles.forms} row`}>
                {/* <!-- First Name --> */}
                <div className="input-group col-lg-6 mb-4">
                  <input
                    id="firstName"
                    type="text"
                    name="firstname"
                    placeholder="Votre Nom"
                    className="form-control bg-white border-left-0 border-md"
                  />
                </div>

                {/* <!-- Last Name --> */}
                <div className="input-group col-lg-6 mb-4">
                  <input
                    id="lastName"
                    type="text"
                    name="lastname"
                    placeholder="Votre Prénom"
                    className="form-control bg-white border-left-0 border-md"
                  />
                </div>

                {/* <!-- Email Address --> */}
                <div className="input-group col-lg-12 mb-4">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Votre Email"
                    className="form-control bg-white border-left-0 border-md"
                  />
                </div>

                {/* <!-- CIN --> */}
                <div className="input-group col-lg-12 mb-4">
                  <input
                    id="phoneNumber"
                    type="tel"
                    name="phone"
                    placeholder="Votre CIN"
                    className="form-control bg-white border-md border-left-0 pl-3"
                  />
                </div>

                {/* <!-- Phone Number --> */}
                <div className="input-group col-lg-12 mb-4">
                  <select
                    id="countryCode"
                    name="countryCode"
                    style={{ maxWidth: '80px' }}
                    className="custom-select form-control bg-white border-left-0 border-md h-100 font-weight-bold text-muted"
                    onChange={handleCountryChange}
                    value={selectedCode}
                  >
                    <option value="">Select</option>
                    {sortedCountries.map((country, index) => (
                      <option key={index} value={country.code}>
                        {selectedCode === country.code
                          ? country.code
                          : `${country.name} ${country.code} `}
                      </option>
                    ))}
                  </select>
                  <input
                    id="phoneNumber"
                    type="tel"
                    name="phone"
                    placeholder="Votre Téléphone"
                    className="form-control bg-white border-md border-left-0 pl-3"
                  />
                </div>

                {/* <!-- study --> */}
                {/* <div className="input-group col-lg-12 mb-4">
                  <select
                    id="studies"
                    name="yearsStudies"
                    className="form-control custom-select bg-white border-left-0 border-md"
                  >
                    <option disabled>Niveau d'études voulu</option>
                    <option value="diplome">Diplômes accrédités (2 ans)</option>
                    <option value="licence">Licence universitaire professionnel</option>
                  </select>
                </div> */}
                <div className="input-group col-lg-12 mb-4 ml-5">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          type="radio"
                          id="diplome"
                          name="yearsStudies"
                          value="diplome"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="diplome">
                          Diplômes accrédités (2 ans)
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-check">
                        <input
                          type="radio"
                          id="licence"
                          name="yearsStudies"
                          value="licence"
                          className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="licence">
                          Licence universitaire professionnel
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Submit Button --> */}
                <div className={`${styles.button} form-group col-lg-12 mx-auto mb-0`}>
                  <button type="submit" className="btn btn-primary btn-block py-2">
                    <span className="font-weight-bold">Déposer votre condidature</span>
                  </button>
                </div>

                {/* <!-- Divider Text --> */}
                <div className="form-group col-lg-12 mx-auto d-flex align-items-center my-4">
                  <div className="border-bottom w-100 ml-5"></div>
                  <span className="px-2 small text-muted font-weight-bold text-muted">OR</span>
                  <div className="border-bottom w-100 mr-5"></div>
                </div>

                {/* <!-- Social Login --> */}
                {/* <div className={`${styles.button} form-group col-lg-12 mx-auto`}>
                  <a href="#" className="btn btn-primary btn-block py-2 btn-facebook">
                    <i className="fa fa-facebook-f mr-2"></i>
                    <span className="font-weight-bold">Continuer avec Facebook</span>
                  </a>
                  <a href="#" className="btn btn-primary btn-block py-2 btn-twitter">
                    <i className="fa fa-twitter mr-2"></i>
                    <span className="font-weight-bold">Continue with Twitter</span>
                  </a>
                </div> */}

                {/* <!-- Already Registered --> */}
                <div className="text-center w-100">
                  <p className="text-muted font-weight-bold">
                    Déjà enregistré?{' '}
                    <a href="#" className="text-primary ml-2">
                      Se connecter
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpSmartComponent;
