/* eslint-disable */
import React, { useState } from 'react';
import styles from './Home-smart.module.css';
import imageheader from '../../assets/promo2019.jpg';
import president from '../../assets/president.png';
import ipirnet from '../../assets/ipirnet.png';
import iconTSDI from '../../assets/icons/tsdi.png';
import ge from '../../assets/icons/ge.png';
import gi from '../../assets/icons/gi.png';
import ad from '../../assets/icons/ad.png';
import Logistique from '../../assets/icons/Logistique.png';
import marketing from '../../assets/icons/marketing.png';
import management from '../../assets/icons/management.png';
import Informatique from '../../assets/icons/Informatique.png';
import life1 from '../../assets/life1.png';
import life2 from '../../assets/life2.png';
import life3 from '../../assets/life3.png';
import life4 from '../../assets/life4.png';
import accreditation from '../../assets/logo-accreditation.png';
import download from '../../assets/iphonevandroid.webp';
import googlePlayBadge from '../../assets/google.png';
import appStoreBadge from '../../assets/apple.png';

function HomeSmartComponent() {
  const diplomas = [
    {
      image: iconTSDI,
      alt: 'Technicien Spécialisé en Développement Informatique',
      title: 'Technicien Spécialisé en Développement Informatique',
      url: 'technicien-specialise-en-developpement-informatique',
    },
    {
      image: ge,
      alt: 'Technicien Spécialisé en Gestion des Entreprises',
      title: 'Technicien Spécialisé en Gestion des Entreprises',
      url: 'technicien-specialise-en-gestion-des-entreprises',
    },
    {
      image: gi,
      alt: 'Technicien en Gestion Informatisée',
      title: 'Technicien en Gestion Informatisée',
      url: 'technicien-en-genie-informatisée',
    },
    {
      image: ad,
      alt: 'Opérateur en Audiovisuel et Digital',
      title: 'Opérateur en Audiovisuel et Digital',
      url: 'operateur-en-audiovisuel-et-digital',
    },
  ];

  const [selectedDiploma, setSelectedDiploma] = useState(null);

  const handleDiplomaClick = (index) => {
    setSelectedDiploma(index);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setSelectedDiploma(index);
    }
  };

  return (
    <>
      {' '}
      <div className={styles.home}>
        {/* header end */}

        {/* section image header */}
        <div className={styles.homeImage}>
          <div className="mt-6">
            <img
              src={imageheader}
              className={styles.headerImage}
              alt="headerImage"
              loading="lazy"
            />

            <div className={styles.content}>
              <h1>Groupe IPIRNET</h1>
              <p className={styles.headerP}>
                Le Groupe IPIRNET est une institution de formation dédiée à l'excellence académique
                et professionnelle.
              </p>
              <div className={`${styles.positionButton} row `}>
                <div className="col-md-6 col-6">
                  <a className={styles.button} href="/about">
                    Notre Groupe
                  </a>
                </div>
                <div className="col-md-6 col-6">
                  <a className={styles.button} href="/licences">
                    Formations
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.container}>
          {/* Section Mot du President */}
          <div className={`${styles.section} row`}>
            <div className="col-md-4 mt-5">
              <img src={president} alt="Président" className={styles.image} />
            </div>
            <div className="col-md-6 mt-5">
              <div className={styles.textBlock}>
                <h2 className={styles.title}>Mot du Président</h2>
                <img className={styles.accreditation} src={accreditation} alt="accreditation" />
                <p className={styles.paragraph}>
                  Bienvenue sur le site du Groupe IPIRNET. Nous sommes ravis de vous présenter notre
                  institution dédiée à l'excellence et à l'innovation dans le domaine de la
                  formation. Chez IPIRNET, nous nous engageons à fournir une éducation de qualité,
                  adaptée aux besoins actuels du marché, pour préparer nos étudiants à réussir dans
                  leurs carrières. Nous croyons fermement que chaque individu possède un potentiel
                  unique et nous sommes ici pour les aider à le réaliser. Explorez notre site pour
                  en savoir plus sur nos programmes et rejoignez-nous dans cette aventure éducative
                  enrichissante.
                </p>
              </div>
            </div>
          </div>

          {/* Section Groupe Ipirnet avec background #ddd */}
          <div className={`${styles.section2} ${styles.backgroundGrey}`}>
            <div className={`${styles.section} row`}>
              <div className="col-md-6">
                <div className={styles.textBlock}>
                  <h2 className={styles.title}>Groupe Ipirnet</h2>
                  <p className={styles.paragraph}>
                    Nous offrons une gamme complète de programmes de formation conçus pour répondre
                    aux besoins actuels du marché du travail et pour aider nos étudiants à atteindre
                    leurs objectifs professionnels. Avec une équipe de formateurs expérimentés et
                    passionnés, nous nous engageons à fournir une éducation de qualité et à
                    encourager le développement personnel de chaque étudiant. Rejoignez-nous pour
                    découvrir une approche innovante et enrichissante de la formation.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <img src={ipirnet} alt="Président" className={styles.image} />
              </div>
            </div>
          </div>
          {/* diplome accredite */}
          <div className={styles.diplomaSection}>
            <h2 className={styles.diplomaTitle}>Diplômes Accrédités</h2>
            <p className={styles.diplomaDescription}>
              Nos diplômes accrédités sont reconnus par les industries et les employeurs pour leur
              excellence académique et leur pertinence professionnelle. En vous inscrivant à nos
              programmes, vous bénéficierez d'une formation rigoureuse et innovante, conçue pour
              vous préparer à réussir dans votre carrière. Découvrez les parcours variés que nous
              proposons et choisissez celui qui correspond le mieux à vos aspirations
              professionnelles.
            </p>
            <img className={styles.accreditationD} src={accreditation} alt="accreditation" />
            <div className={styles.diplomaList}>
              {diplomas.map((diploma, index) => (
                <div
                  key={diploma.id}
                  className={`${styles.diplomaItem} ${selectedDiploma === index ? styles.selected : ''}`}
                  onClick={() => handleDiplomaClick(index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  role="button"
                  tabIndex={0}
                >
                  <a
                    className={styles.diplomaCLick}
                    href={`/module/${diploma.title.replace(/\s+/g, '-')}`}
                  >
                    <img src={diploma.image} alt={diploma.alt} className={styles.diplomaIcon} />
                    <h3 className={styles.titles}>{diploma.title}</h3>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Section Licence Professionnelle */}
        <div className={styles.container}>
          <div className={styles.licenseSection}>
            <h2 className={styles.licenseTitle}>Licence Professionnelle</h2>
            <img className={styles.accreditationL} src={accreditation} alt="accreditation" />
            <p className={styles.licenseDescription}>
              Notre Licence Professionnelle vous offre une formation complète et spécialisée,
              alliant théorie et pratique, pour vous préparer efficacement au monde professionnel.
              Grâce à nos partenariats avec des entreprises de renom, bénéficiez d'une immersion
              directe dans votre futur métier à travers des stages et des projets appliqués.
              Explorez nos différents programmes et découvrez celui qui correspond le mieux à vos
              ambitions et à votre carrière.
            </p>
            <div className={styles.programsContainer}>
              <div className={styles.program}>
                <img src={management} alt="Management et RH" className={styles.programIcon} />
                <h3>Management Et RH</h3>
                <button
                  type="button"
                  className={styles.programButton}
                  onClick={() => (window.location.href = '/licences#managementEtRH')}
                >
                  Savoir Plus
                </button>
              </div>
              <div className={styles.program}>
                <img src={Logistique} alt="Logistique" className={styles.programIcon} />
                <h3>Logistique</h3>
                <button
                  type="button"
                  className={styles.programButton}
                  onClick={() => (window.location.href = '/licences#logistique')}
                >
                  Savoir Plus
                </button>
              </div>
              <div className={styles.program}>
                <img src={marketing} alt="Commerce & Marketing" className={styles.programIcon} />
                <h3>Commerce & Marketing</h3>
                <button
                  type="button"
                  className={styles.programButton}
                  onClick={() => (window.location.href = '/licences#commerceMarketing')}
                >
                  Savoir Plus
                </button>
              </div>
              <div className={styles.program}>
                <img src={Informatique} alt="Informatique" className={styles.programIcon} />
                <h3>Informatique</h3>
                <button
                  type="button"
                  className={styles.programButton}
                  onClick={() => (window.location.href = '/licences#informatique')}
                >
                  Savoir Plus
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Section La Vie Étudiante */}
        <div className={`${styles.container}`}>
          <div className={styles.studentLifeSection}>
            <h2 className={styles.studentLifeTitle}>La Vie Éstudiantine</h2>
            <p className={styles.studentLifeDescription}>
              Un programme varié d'activités extrascolaires favorisant à la fois le divertissement
              et le développement des compétences personnelles et sociales.
            </p>
            <div className={styles.studentLifeImage}>
              <div className={styles.div1}>
                <img src={life1} alt="Étudiants dans un parc" className={styles.life1} />
              </div>
              <div className={styles.div2}>
                <img src={life2} alt="Étudiants sur des marches" className={styles.life2} />
              </div>
              <div className={styles.div3}>
                <img src={life3} alt="Étudiants sur des marches" className={styles.life3} />
              </div>
              <div className={styles.div4}>
                <img src={life4} alt="Étudiants sur des marches" className={styles.life4} />
              </div>
            </div>
          </div>
        </div>
        {/* section applications */}

        <div className={styles.downloadAppSection}>
          <h2 className={styles.downloadAppTitle}>
            Téléchargez Votre Application pour Android et iOS
          </h2>
          <div className={styles.phonesContainer}>
            <img src={download} alt="Téléphone gauche" className={styles.phoneImage} />
          </div>
          <div className={styles.badgesContainer}>
            <a
              href="https://www.apple.com/ios/app-store/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStoreBadge} alt="App Store" className={styles.storeBadge} />
            </a>
            <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayBadge} alt="Google Play Store" className={styles.storeBadge} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeSmartComponent;
