/* eslint-disable */
import React from 'react';
import { useParams } from 'react-router-dom';

import { VscVerifiedFilled } from 'react-icons/vsc';
import styles from './Module-smart.module.css';
import formation from '../../assets/formations.png';
import image from '../../assets/working.jpg';

function ModuleSmartComponent() {
  // Récupérer l'identifiant de la formation à partir de l'URL
  const { formationId } = useParams();
  // Transformer l'identifiant de la formation pour correspondre aux clés dans formationDetails
  const formattedFormationId = formationId.replace(/-/g, ' ');

  const formationDetails = {
    'Management et Gestion des Entreprises': {
      title: 'Management et Gestion des Entreprises',
      description:
        "La filière Management et Gestion des Entreprises forme des professionnels capables de diriger, organiser et gérer les activités d'une entreprise. Les étudiants acquièrent des compétences en stratégie, en gestion financière, en marketing, et en gestion des ressources humaines. La formation inclut des études de cas, des projets pratiques et des stages en entreprise pour favoriser une intégration réussie sur le marché du travail.",
      modules: [
        { id: 1, title: 'M1', description: 'Principes de Management', hours: 20 },
        { id: 2, title: 'M1', description: 'Gestion Financière', hours: 20 },
        { id: 3, title: 'M1', description: 'Marketing et Communication', hours: 20 },
        { id: 4, title: 'M1', description: 'Comptabilité Générale', hours: 20 },
        { id: 5, title: 'M1', description: 'Gestion des Ressources Humaines', hours: 20 },
        { id: 6, title: 'M1', description: 'Stratégie d’Entreprise', hours: 20 },
        { id: 7, title: 'M1', description: 'Entrepreneuriat', hours: 20 },
        { id: 8, title: 'M1', description: 'Systèmes d’Information de Gestion', hours: 20 },
        { id: 9, title: 'M1', description: 'Stage en Entreprise', hours: 20 },
        { id: 10, title: 'M2', description: 'Leadership et Gestion d’Équipe', hours: 20 },
        { id: 11, title: 'M2', description: 'Gestion de Projet', hours: 20 },
        { id: 12, title: 'M2', description: 'Économie d’Entreprise', hours: 20 },
        { id: 13, title: 'M2', description: 'Analyse des Données', hours: 20 },
        { id: 14, title: 'M2', description: 'Gestion des Risques', hours: 20 },
        {
          id: 15,
          title: 'M2',
          description: 'Développement Durable et Responsabilité Sociale',
          hours: 20,
        },
        { id: 16, title: 'M2', description: 'Stage de Fin d’Études', hours: 20 },
        { id: 17, title: 'M2', description: 'Projet de Fin d’Études', hours: 20 },
      ],
      unblock: [
        { title: 'Manager : Coordination des équipes et supervision des opérations.' },
        { title: 'Directeur Financier : Élaboration et suivi des budgets et prévisions.' },
        {
          title: 'Responsable Marketing : Élaboration de stratégies de marketing et communication.',
        },
        { title: 'Consultant en Management : Accompagnement des entreprises dans leur stratégie.' },
        { title: 'Entrepreneur : Création et gestion de sa propre entreprise.' },
        { title: 'Directeur Général : Pilotage stratégique de l’entreprise.' },
      ],
    },

    'Gestion des Ressources Humaines': {
      title: 'Gestion des Ressources Humaines',
      description:
        "La filière Gestion des Ressources Humaines forme des professionnels capables de gérer efficacement le capital humain d'une entreprise. Les étudiants acquièrent des compétences en recrutement, en gestion des talents, en formation et développement, ainsi qu'en gestion des relations sociales. La formation combine des approches théoriques et pratiques, avec des études de cas et des stages en entreprise pour une immersion professionnelle.",
      modules: [
        {
          id: 1,
          title: 'M1',
          description: 'Introduction à la Gestion des Ressources Humaines',
          hours: 20,
        },
        { id: 2, title: 'M1', description: 'Recrutement et Sélection', hours: 20 },
        { id: 3, title: 'M1', description: 'Gestion des Talents', hours: 20 },
        { id: 4, title: 'M1', description: 'Formation et Développement', hours: 20 },
        { id: 5, title: 'M1', description: 'Droit du Travail', hours: 20 },
        { id: 6, title: 'M1', description: 'Gestion des Performances', hours: 20 },
        { id: 7, title: 'M1', description: 'Relations Sociales et Négociations', hours: 20 },
        { id: 8, title: 'M1', description: 'Gestion de la Diversité', hours: 20 },
        { id: 9, title: 'M1', description: 'Stratégie RH', hours: 20 },
        { id: 10, title: 'M1', description: 'Stage en Entreprise', hours: 20 },
        { id: 11, title: 'M2', description: 'Leadership et Management', hours: 20 },
        { id: 12, title: 'M2', description: 'Gestion des Conflits', hours: 20 },
        {
          id: 13,
          title: 'M2',
          description: "Systèmes d'Information des Ressources Humaines",
          hours: 20,
        },
        { id: 14, title: 'M2', description: 'Audit Social', hours: 20 },
        { id: 15, title: 'M2', description: 'Gestion des Changement', hours: 20 },
        { id: 16, title: 'M2', description: 'Développement Organisationnel', hours: 20 },
        { id: 17, title: 'M2', description: 'Stage de Fin d’Études', hours: 20 },
        { id: 18, title: 'M2', description: 'Projet de Fin d’Études', hours: 20 },
      ],
      unblock: [
        { title: 'Chargé de Ressources Humaines : Gestion des processus RH au quotidien.' },
        {
          title:
            'Responsable du Recrutement : Élaboration et mise en œuvre des stratégies de recrutement.',
        },
        { title: 'Formateur : Conception et animation de formations pour les employés.' },
        {
          title: 'Responsable de la Gestion des Talents : Développement et rétention des talents.',
        },
        {
          title:
            'Consultant en Ressources Humaines : Accompagnement des entreprises dans leur stratégie RH.',
        },
        {
          title:
            'Directeur des Ressources Humaines : Élaboration de la stratégie RH au niveau de l’entreprise.',
        },
      ],
    },

    "Responsable d'Unité Opérationnelle Logistique": {
      title: "Responsable d'Unité Opérationnelle Logistique",
      description:
        "La filière Responsable d'Unité Opérationnelle Logistique forme des professionnels capables de superviser et d'optimiser les opérations logistiques au sein d'une entreprise. Les étudiants acquièrent des compétences en gestion de la chaîne d'approvisionnement, en planification des transports, en gestion des stocks et en analyse des performances logistiques. La formation inclut des études de cas pratiques et des stages en entreprise pour une meilleure intégration dans le monde professionnel.",
      modules: [
        { id: 1, title: 'M1', description: 'Principes de Logistique', hours: 20 },
        { id: 2, title: 'M1', description: 'Gestion des Stocks', hours: 20 },
        { id: 3, title: 'M1', description: 'Planification des Transports', hours: 20 },
        { id: 4, title: 'M1', description: "Gestion de la Chaîne d'Approvisionnement", hours: 20 },
        { id: 5, title: 'M1', description: "Systèmes d'Information Logistique", hours: 20 },
        { id: 6, title: 'M1', description: 'Optimisation des Processus Logistiques', hours: 20 },
        { id: 7, title: 'M1', description: 'Droit du Transport', hours: 20 },
        { id: 8, title: 'M1', description: 'Management d’Équipe Logistique', hours: 20 },
        { id: 9, title: 'M1', description: 'Gestion des Risques Logistiques', hours: 20 },
        { id: 10, title: 'M1', description: 'Stage en Entreprise', hours: 20 },
        { id: 11, title: 'M2', description: 'Logistique Internationale', hours: 20 },
        { id: 12, title: 'M2', description: 'Stratégies de Transport', hours: 20 },
        { id: 13, title: 'M2', description: 'Analyse de Performance Logistique', hours: 20 },
        { id: 14, title: 'M2', description: 'Sourcing et Achats Logistiques', hours: 20 },
        { id: 15, title: 'M2', description: 'Gestion de Projet Logistique', hours: 20 },
        { id: 16, title: 'M2', description: 'Durabilité et Logistique Verte', hours: 20 },
        { id: 17, title: 'M2', description: 'Négociation Commerciale', hours: 20 },
        { id: 18, title: 'M2', description: 'Stage de Fin d’Études', hours: 20 },
        { id: 19, title: 'M2', description: 'Projet de Fin d’Études', hours: 20 },
      ],
      unblock: [
        { title: 'Logisticien : Gestion des opérations logistiques au quotidien.' },
        { title: 'Responsable des Approvisionnements : Supervision des achats et des stocks.' },
        {
          title:
            'Planificateur Logistique : Élaboration des plannings de transport et de stockage.',
        },
        {
          title:
            'Coordinateur Logistique : Coordination entre les différents acteurs de la logistique.',
        },
        {
          title:
            'Analyste Logistique : Analyse des données logistiques pour optimiser les opérations.',
        },
        { title: 'Directeur Logistique : Élaboration de la stratégie logistique de l’entreprise.' },
      ],
    },

    'Commerce Marketing': {
      title: 'Commerce Marketing',
      description:
        'La filière Commerce Marketing prépare les étudiants à comprendre et à maîtriser les stratégies commerciales et marketing. Les étudiants acquièrent des compétences en gestion de la relation client, en analyse de marché, en communication et en négociation. La formation inclut des études de cas, des projets pratiques et des stages en entreprise pour une immersion professionnelle.',
      modules: [
        { id: 1, title: 'M1', description: 'Principes de Marketing', hours: 20 },
        { id: 2, title: 'M1', description: 'Comportement du Consommateur', hours: 20 },
        { id: 3, title: 'M1', description: 'Stratégies de Vente', hours: 20 },
        { id: 4, title: 'M1', description: 'Gestion de la Relation Client', hours: 20 },
        { id: 5, title: 'M1', description: 'Communication Marketing', hours: 20 },
        { id: 6, title: 'M1', description: 'Analyse de Marché', hours: 20 },
        { id: 7, title: 'M1', description: 'Marketing Digital', hours: 20 },
        { id: 8, title: 'M1', description: 'Techniques de Négociation', hours: 20 },
        { id: 9, title: 'M1', description: 'Droit Commercial', hours: 20 },
        { id: 10, title: 'M1', description: 'Gestion de Projet Marketing', hours: 20 },
        { id: 11, title: 'M1', description: 'Stage en Entreprise', hours: 20 },
        { id: 12, title: 'M1', description: 'Communication Professionnelle', hours: 20 },
        { id: 13, title: 'M2', description: 'Marketing Stratégique', hours: 20 },
        { id: 14, title: 'M2', description: 'Branding et Gestion de Marque', hours: 20 },
        { id: 15, title: 'M2', description: 'E-commerce et Vente en Ligne', hours: 20 },
        { id: 16, title: 'M2', description: 'Analyse de Données Marketing', hours: 20 },
        { id: 17, title: 'M2', description: 'Publicité et Promotion', hours: 20 },
        { id: 18, title: 'M2', description: 'Gestion de la Performance Marketing', hours: 20 },
        { id: 19, title: 'M2', description: 'Management d’Équipe Commerciale', hours: 20 },
        { id: 20, title: 'M2', description: 'Stage de Fin d’Études', hours: 20 },
        { id: 21, title: 'M2', description: 'Projet de Fin d’Études', hours: 20 },
      ],
      unblock: [
        { title: 'Chargé de Marketing : Élaboration et mise en œuvre des stratégies marketing.' },
        {
          title: 'Responsable Commercial : Gestion des équipes de vente et des relations clients.',
        },
        { title: 'Analyste Marketing : Analyse des données et des tendances du marché.' },
        { title: 'Chef de Produit : Gestion du cycle de vie d’un produit ou d’une gamme.' },
        {
          title:
            'Consultant en Marketing Digital : Accompagnement des entreprises dans leur transformation numérique.',
        },
        {
          title:
            'Responsable E-commerce : Gestion des ventes en ligne et des plateformes numériques.',
        },
        {
          title:
            'Directeur Marketing : Élaboration des stratégies marketing au niveau de l’entreprise.',
        },
      ],
    },

    'Ingénierie en Logiciel': {
      title: 'Ingénierie en Logiciel',
      description:
        "La filière Ingénierie en Logiciel forme des professionnels compétents dans la conception, le développement et la maintenance de logiciels. Les étudiants acquièrent des compétences en programmation, génie logiciel, gestion de projets informatiques, et méthodologies de développement. La formation comprend des cours théoriques et pratiques, avec des stages en entreprise pour renforcer l'expérience professionnelle.",
      modules: [
        { id: 1, title: 'M1', description: 'Programmation Orientée Objet', hours: 20 },
        { id: 2, title: 'M1', description: 'Structure de Données et Algorithmes', hours: 20 },
        { id: 3, title: 'M1', description: 'Bases de Données', hours: 20 },
        { id: 4, title: 'M1', description: "Systèmes d'Exploitation", hours: 20 },
        { id: 5, title: 'M1', description: 'Génie Logiciel', hours: 20 },
        { id: 6, title: 'M1', description: 'Réseaux et Sécurité', hours: 20 },
        { id: 7, title: 'M1', description: 'Méthodologie de Développement Logiciel', hours: 20 },
        { id: 8, title: 'M1', description: 'Mathématiques Discrètes', hours: 20 },
        { id: 9, title: 'M1', description: 'Anglais Technique', hours: 20 },
        { id: 10, title: 'M1', description: 'Communication Professionnelle', hours: 20 },
        { id: 11, title: 'M1', description: "Stage d'Application", hours: 20 },
        { id: 12, title: 'M1', description: 'Projet Informatique', hours: 20 },
        { id: 13, title: 'M2', description: 'Développement Web et Mobile', hours: 20 },
        { id: 14, title: 'M2', description: 'Conception et Architecture Logicielle', hours: 20 },
        { id: 15, title: 'M2', description: 'Intelligence Artificielle', hours: 20 },
        { id: 16, title: 'M2', description: 'Sécurité des Applications', hours: 20 },
        { id: 17, title: 'M2', description: 'DevOps et Intégration Continue', hours: 20 },
        { id: 18, title: 'M2', description: 'Big Data et Analyse de Données', hours: 20 },
        { id: 19, title: 'M2', description: 'Management de Projet Informatique', hours: 20 },
        { id: 20, title: 'M2', description: 'Développement Logiciel Avancé', hours: 20 },
        { id: 21, title: 'M2', description: 'Audit et Qualité Logicielle', hours: 20 },
        { id: 22, title: 'M2', description: 'Communication Professionnelle en Anglais', hours: 20 },
        { id: 23, title: 'M2', description: 'Stage en Entreprise', hours: 20 },
        { id: 24, title: 'M2', description: "Projet de Fin d'Études", hours: 20 },
      ],
      unblock: [
        { title: 'Développeur Logiciel : Conception et développement de logiciels sur mesure.' },
        { title: 'Architecte Logiciel : Conception de l’architecture des applications.' },
        {
          title:
            'Ingénieur en Intelligence Artificielle : Développement d’applications utilisant des techniques d’IA.',
        },
        { title: 'Ingénieur DevOps : Gestion de l’infrastructure et des déploiements continus.' },
        { title: 'Ingénieur Sécurité Logicielle : Sécurisation des applications et des systèmes.' },
        { title: 'Chef de Projet Informatique : Gestion de projets de développement logiciel.' },
        {
          title:
            'Consultant en Ingénierie Logicielle : Accompagnement des entreprises dans la conception et la mise en œuvre de solutions logicielles.',
        },
      ],
    },

    "Méthode Informatique Appliquée pour la Gestion d'Entreprise": {
      title: "Méthode Informatique Appliquée pour la Gestion d'Entreprise",
      description:
        "La filière Méthode Informatique Appliquée pour la Gestion d'Entreprise (MIAGE) forme des professionnels aptes à concevoir et développer des solutions informatiques adaptées à la gestion d'entreprise. Les étudiants acquièrent des compétences en informatique, gestion d’entreprise, et management des systèmes d'information. La formation allie théorie et pratique, avec des stages pour favoriser l'intégration dans le milieu professionnel.",
      modules: [
        { id: 1, title: 'M1', description: 'Informatique de gestion', hours: 20 },
        { id: 2, title: 'M1', description: 'Algorithme et programmation', hours: 20 },
        { id: 3, title: 'M1', description: 'Bases de données', hours: 20 },
        { id: 4, title: 'M1', description: "Systèmes d'information", hours: 20 },
        { id: 5, title: 'M1', description: 'Analyse et conception des systèmes', hours: 20 },
        { id: 6, title: 'M1', description: 'Gestion financière', hours: 20 },
        { id: 7, title: 'M1', description: 'Gestion des ressources humaines', hours: 20 },
        { id: 8, title: 'M1', description: 'Comptabilité générale', hours: 20 },
        { id: 9, title: 'M1', description: "Droit de l'entreprise", hours: 20 },
        { id: 10, title: 'M1', description: 'Techniques de communication', hours: 20 },
        { id: 11, title: 'M1', description: 'Anglais technique', hours: 20 },
        { id: 12, title: 'M1', description: "Stage d'application", hours: 20 },
        { id: 13, title: 'M1', description: 'Projet de développement informatique', hours: 20 },
        { id: 14, title: 'M2', description: "Management des systèmes d'information", hours: 20 },
        { id: 15, title: 'M2', description: 'Programmation avancée', hours: 20 },
        { id: 16, title: 'M2', description: 'E-commerce et e-business', hours: 20 },
        { id: 17, title: 'M2', description: "Sécurité des systèmes d'information", hours: 20 },
        { id: 18, title: 'M2', description: 'Gestion de projet', hours: 20 },
        { id: 19, title: 'M2', description: "Audit des systèmes d'information", hours: 20 },
        { id: 20, title: 'M2', description: 'Big Data et Data Science', hours: 20 },
        { id: 21, title: 'M2', description: 'Marketing digital', hours: 20 },
        { id: 22, title: 'M2', description: 'Intelligence artificielle', hours: 20 },
        { id: 23, title: 'M2', description: 'Communication professionnelle', hours: 20 },
        { id: 24, title: 'M2', description: 'Stage en entreprise', hours: 20 },
        { id: 25, title: 'M2', description: "Projet de fin d'études", hours: 20 },
      ],
      unblock: [
        {
          title:
            "Développeur d'applications de gestion : Conception de logiciels pour les entreprises.",
        },
        {
          title:
            "Analyste en systèmes d'information : Analyse et optimisation des systèmes d'information.",
        },
        { title: 'Consultant ERP : Mise en place et gestion des solutions ERP en entreprise.' },
        { title: 'Gestionnaire de base de données : Conception et gestion des bases de données.' },
        {
          title:
            "Chef de projet informatique : Gestion de projets informatiques dans un contexte de gestion d'entreprise.",
        },
        {
          title:
            "Responsable de la sécurité des systèmes d'information : Mise en place de politiques de sécurité.",
        },
        {
          title:
            'Consultant en transformation digitale : Accompagnement des entreprises dans leur digitalisation.',
        },
      ],
    },

    'Technicien Spécialisé en Développement Informatique': {
      title: 'Technicien Spécialisé en Développement Informatique',
      description:
        'La formation en développement informatique a pour objectif de former des techniciens spécialisés capables de concevoir, développer et maintenir des applications et systèmes informatiques. Les étudiants apprendront à maîtriser divers langages de programmation, à utiliser des outils de développement modernes, et à appliquer des méthodologies de gestion de projet. Ils seront formés pour analyser les besoins des utilisateurs, rédiger des cahiers des charges, et implémenter des solutions logicielles adaptées. En outre, la formation inclut des compétences en support technique et maintenance des systèmes pour assurer leur performance et évolutivité. Les diplômés seront ainsi prêts à répondre aux exigences du marché professionnel et à contribuer efficacement au développement technologique des entreprises.',
      modules: [
        { id: 1, title: 'M1', description: 'Métier et formation', hours: 20 },
        { id: 2, title: 'M1', description: 'L’entreprise et son environnement', hours: 20 },
        { id: 3, title: 'M1', description: 'Notion de mathématique appliquée', hours: 20 },
        { id: 4, title: 'M1', description: 'Gestion du temps', hours: 20 },
        { id: 5, title: 'M1', description: 'Veille technologique', hours: 20 },
        { id: 6, title: 'M1', description: 'Production des documents', hours: 20 },
        { id: 7, title: 'M1', description: 'Communication interpersonnelle', hours: 20 },
        { id: 8, title: 'M1', description: "Logiciel d'application", hours: 20 },
        { id: 9, title: 'M1', description: 'Initiation à la gestion de projet', hours: 20 },
        { id: 10, title: 'M1', description: 'Technique de programmation structurée', hours: 20 },
        { id: 11, title: 'M1', description: 'Langage de programmation structurée', hours: 20 },
        { id: 12, title: 'M1', description: 'Programmation orienté objet', hours: 20 },
        { id: 13, title: 'M1', description: "Technique de recherche d'emploi", hours: 20 },
        { id: 14, title: 'M1', description: "Installation d'un poste informatique", hours: 20 },
        { id: 15, title: 'M1', description: 'Com. en anglais dans un contexte', hours: 20 },
        { id: 16, title: 'M1', description: 'Assistante technique à la clientèle', hours: 20 },
        {
          id: 17,
          title: 'M1',
          description: 'Soutient technique en milieu de travail (I) ',
          hours: 20,
        },
        { id: 18, title: 'M2', description: 'Système de gestion de base de données I', hours: 20 },
        { id: 19, title: 'M2', description: 'Analyse et conception orientée objet ', hours: 20 },
        { id: 20, title: 'M2', description: 'Programmation client serveur', hours: 20 },
        { id: 21, title: 'M2', description: "Déploiement d'application ", hours: 20 },
        { id: 22, title: 'M2', description: 'Introduction aux réseaux informatique ', hours: 20 },
        { id: 23, title: 'M2', description: 'System de gestion de base de données II ', hours: 20 },
        { id: 24, title: 'M2', description: 'Application hypermédias ', hours: 20 },
        { id: 25, title: 'M2', description: 'Programmation de site web dynamique ', hours: 20 },
        { id: 26, title: 'M2', description: 'Programmation Evénementielle ', hours: 20 },
        { id: 27, title: 'M2', description: "Projet de conception de fin d'étude ", hours: 20 },
        {
          id: 28,
          title: 'M2',
          description: 'Conception et Modélisation d’un Système d’information ',
          hours: 20,
        },
        { id: 29, title: 'M1', description: 'Intégration au milieu du travail ', hours: 20 },
      ],
      unblock: [
        { title: 'Développeur logiciel: Conception et développement de logiciels sur mesure.' },
        { title: "Développeur web: Création et maintenance de sites web et d'applications web." },
        {
          title:
            'Analyste programmeur: Analyse des besoins et développement de solutions informatiques.',
        },
        {
          title:
            'Administrateur de bases de données: Gestion et optimisation des bases de données.',
        },
        {
          title:
            'Support technique: Assistance et résolution des problèmes informatiques des utilisateurs.',
        },
        { title: 'Chef de projet informatique: Gestion de projets de développement de A à Z.' },
      ],
    },
    'Technicien Spécialisé en Gestion des Entreprises': {
      title: 'Technicien Spécialisé en Gestion des Entreprises',
      description:
        "La formation en Gestion des Entreprises vise à préparer des techniciens spécialisés capables de gérer efficacement les opérations administratives, financières, et humaines au sein d'une entreprise. Les étudiants acquerront des compétences en comptabilité, gestion financière, gestion des ressources humaines, et administration. Ils apprendront à analyser des états financiers, à gérer la paie et les obligations sociales, et à optimiser les processus internes. L'objectif est de former des professionnels capables de contribuer à la performance globale de l'entreprise et à la prise de décisions stratégiques grâce à une maîtrise des outils et logiciels de gestion.",
      modules: [
        { id: 1, title: 'M1', description: 'Notions Mathématiques', hours: 20 },
        { id: 2, title: 'M1', description: 'Algorithme & Logique de Programmation', hours: 20 },
        { id: 3, title: 'M1', description: 'Programmation Informatique', hours: 20 },
        {
          id: 4,
          title: 'M1',
          description: 'Architecture et Fonctionnement des Ordinateurs',
          hours: 20,
        },
        { id: 5, title: 'M1', description: 'Systèmes  d’Exploitation', hours: 20 },
        { id: 6, title: 'M1', description: 'Bureautiques', hours: 20 },
        { id: 7, title: 'M1', description: 'Gestion Commerciale ', hours: 20 },
        { id: 8, title: 'M1', description: 'Organisation d’Entreprise ', hours: 20 },
        { id: 9, title: 'M1', description: 'Statistique', hours: 20 },
        { id: 10, title: 'M1', description: 'Comptabilité Générale', hours: 20 },
        { id: 11, title: 'M1', description: 'Communication en Français', hours: 20 },
        { id: 12, title: 'M1', description: 'Communication en Anglais', hours: 20 },
        { id: 13, title: 'M1', description: 'Stages en Entreprise   ', hours: 20 },
        {
          id: 14,
          title: 'M2',
          description: 'Analyse et conception de systèmes d’Information',
          hours: 20,
        },
        { id: 15, title: 'M2', description: 'Réseaux Informatique', hours: 20 },
        { id: 16, title: 'M2', description: 'Access ', hours: 20 },
        { id: 17, title: 'M2', description: 'Internet', hours: 20 },
        { id: 18, title: 'M2', description: 'Programmation événementielle ', hours: 20 },
        { id: 19, title: 'M2', description: 'Gestion de Production', hours: 20 },
        { id: 20, title: 'M2', description: 'Gestion des approvisionnements', hours: 20 },
        { id: 21, title: 'M2', description: 'Gestion Financière', hours: 20 },
        { id: 22, title: 'M2', description: 'Qualité Logiciels', hours: 20 },
        { id: 23, title: 'M2', description: 'Organisation d’entreprise', hours: 20 },
        { id: 24, title: 'M2', description: 'Comptabilité Analytique', hours: 20 },
        { id: 25, title: 'M2', description: 'Droit des Affaires', hours: 20 },
        { id: 26, title: 'M2', description: 'Droit Social', hours: 20 },
        {
          id: 27,
          title: 'M1',
          description: 'Communication Professionnelle en Français',
          hours: 20,
        },
        { id: 28, title: 'M2', description: 'Communication Prof. en Arabe', hours: 20 },
        { id: 29, title: 'M2', description: 'Communication Professionnelle en Anglais', hours: 20 },
        { id: 26, title: 'M2', description: 'Stages en Entreprise', hours: 20 },
      ],
      unblock: [
        {
          title:
            "Responsable administratif: Gestion des opérations administratives et organisationnelles au sein d'une entreprise.",
        },
        {
          title:
            'Comptable : Préparation et analyse des états financiers, gestion des écritures comptables.',
        },
        {
          title:
            'Gestionnaire de paie : Administration des salaires, des charges sociales et des déclarations légales.',
        },
        {
          title:
            'Assistant de direction : Support aux cadres dirigeants dans la gestion des tâches administratives et organisationnelles.',
        },
        {
          title:
            'Chargé de ressources humaines : Recrutement, gestion des carrières, et gestion des relations avec les employés.',
        },
        {
          title:
            "Contrôleur de gestion : Analyse de la performance financière et opérationnelle de l'entreprise pour aider à la prise de décision.",
        },
      ],
    },
    'Technicien en Gestion Informatisée': {
      title: 'Technicien en Gestion Informatisée',
      description:
        "La filière Technicien en Gestion Informatisée (TGI) forme des professionnels capables de concevoir, développer et gérer des systèmes d'information. Les étudiants acquièrent des compétences en programmation, en gestion de bases de données, en réseaux informatiques ainsi qu'en gestion d'entreprise. La formation combine des enseignements théoriques et pratiques, incluant des stages en entreprise pour une meilleure intégration professionnelle.",
      modules: [
        { id: 1, title: 'M1', description: 'Notions Mathématiques', hours: 20 },
        { id: 2, title: 'M1', description: 'Algorithme & Logique de Programmation', hours: 20 },
        { id: 3, title: 'M1', description: 'Programmation Informatique', hours: 20 },
        {
          id: 4,
          title: 'M1',
          description: 'Architecture et Fonctionnement des Ordinateurs',
          hours: 20,
        },
        { id: 5, title: 'M1', description: 'Systèmes d’Exploitation', hours: 20 },
        { id: 6, title: 'M1', description: 'Bureautiques', hours: 20 },
        { id: 7, title: 'M1', description: 'Gestion Commerciale', hours: 20 },
        { id: 8, title: 'M1', description: 'Organisation d’Entreprise', hours: 20 },
        { id: 9, title: 'M1', description: 'Statistique', hours: 20 },
        { id: 10, title: 'M1', description: 'Comptabilité Générale', hours: 20 },
        { id: 11, title: 'M1', description: 'Communication en Français', hours: 20 },
        { id: 12, title: 'M1', description: 'Communication en Anglais', hours: 20 },
        { id: 13, title: 'M1', description: 'Stages en Entreprise', hours: 20 },
        {
          id: 14,
          title: 'M2',
          description: 'Analyse et conception de systèmes d’Information',
          hours: 20,
        },
        { id: 15, title: 'M2', description: 'Réseaux Informatique', hours: 20 },
        { id: 16, title: 'M2', description: 'Access', hours: 20 },
        { id: 17, title: 'M2', description: 'Internet', hours: 20 },
        { id: 18, title: 'M2', description: 'Programmation événementielle', hours: 20 },
        { id: 19, title: 'M2', description: 'Gestion de Production', hours: 20 },
        { id: 20, title: 'M2', description: 'G. des approvisionnements', hours: 20 },
        { id: 21, title: 'M2', description: 'Gestion Financière', hours: 20 },
        { id: 22, title: 'M2', description: 'Qualité Logiciels', hours: 20 },
        { id: 23, title: 'M2', description: 'Organisation d’entreprise', hours: 20 },
        { id: 24, title: 'M2', description: 'Comptabilité Analytique', hours: 20 },
        { id: 25, title: 'M2', description: 'Droit Social', hours: 20 },
        { id: 26, title: 'M2', description: 'Droit des Affaires', hours: 20 },
        {
          id: 27,
          title: 'M2',
          description: 'Communication Professionnelle en Français',
          hours: 20,
        },
        { id: 28, title: 'M2', description: 'Communication Prof. en Arabe', hours: 20 },
        { id: 29, title: 'M2', description: 'Communication Professionnelle en Anglais', hours: 20 },
        { id: 30, title: 'M2', description: 'Stages en Entreprise', hours: 20 },
        { id: 31, title: 'M2', description: 'Droit Informatique', hours: 20 },
      ],
      unblock: [
        { title: 'Développeur logiciel : Conception et développement de logiciels sur mesure.' },
        {
          title:
            'Technicien support informatique : Assistance technique et maintenance des systèmes informatiques.',
        },
        {
          title:
            'Administrateur de bases de données : Gestion et optimisation des bases de données.',
        },
        {
          title:
            'Analyste programmeur : Développement d’applications et analyse des besoins utilisateurs.',
        },
        {
          title:
            'Gestionnaire de systèmes d’information : Supervision et administration des systèmes d’information d’une entreprise.',
        },
        {
          title:
            'Consultant en informatique : Conseil et accompagnement dans le choix de solutions informatiques.',
        },
        {
          title:
            'Responsable de la sécurité informatique : Mise en place de politiques de sécurité et protection des données.',
        },
      ],
    },
    'Opérateur en Audiovisuel et Digital': {
      title: 'Opérateur en Audiovisuel et Digital',
      description:
        "La filière Opérateur en Audiovisuel et Digital forme des professionnels capables de créer et de gérer des contenus audiovisuels et numériques. Les étudiants acquièrent des compétences en production vidéo, montage, création graphique, ainsi qu'en gestion de contenus digitaux. La formation inclut des modules techniques et pratiques pour une intégration optimale dans le secteur de l'audiovisuel et du digital.",
      modules: [
        { id: 1, title: 'M1', description: 'Application informatique et bureautique', hours: 20 },
        { id: 2, title: 'M1', description: 'Clas. org. Adm. et archivage', hours: 20 },
        { id: 3, title: 'M1', description: 'Comptabilité générale', hours: 20 },
        { id: 4, title: 'M1', description: 'Organisation de l’entreprise', hours: 20 },
        { id: 5, title: 'M1', description: 'Droit com, soc et du travail', hours: 20 },
        {
          id: 6,
          title: 'M1',
          description: 'Techniques de communication et d’expression',
          hours: 20,
        },
        { id: 7, title: 'M1', description: 'Anglais technique', hours: 20 },
        { id: 8, title: 'M1', description: 'Maîtrise du clavier', hours: 20 },
        { id: 9, title: 'M1', description: 'Technique d’accueil et de réception', hours: 20 },
        { id: 10, title: 'M1', description: 'Pré. accom. suivi des stages', hours: 20 },
        { id: 11, title: 'M1', description: 'Technique de recherche d’emploi', hours: 20 },
        { id: 12, title: 'M1', description: 'Notion en Internet', hours: 20 },
        { id: 13, title: 'M1', description: 'Stage d’application', hours: 20 },
      ],
      unblock: [
        {
          title:
            'Technicien en production audiovisuelle : Création et montage de vidéos pour divers supports.',
        },
        {
          title:
            'Opérateur de caméra : Captation d’images pour la télévision, le cinéma ou le web.',
        },
        {
          title:
            'Assistant réalisateur : Assistance dans la production et la gestion des projets audiovisuels.',
        },
        { title: 'Monteur vidéo : Montage et post-production de contenus vidéo.' },
        {
          title:
            'Technicien en multimédia : Gestion et production de contenus numériques et interactifs.',
        },
        {
          title:
            'Technicien en animation graphique : Création d’animations et d’effets spéciaux pour le digital.',
        },
        {
          title:
            'Technicien en gestion de contenu digital : Gestion des plateformes et des contenus numériques.',
        },
      ],
    },
  };

  const selectedFormation = formationDetails[formattedFormationId];
  if (!selectedFormation) {
    return <div>Formation non trouvée</div>;
  }

  return (
    <>
      <main>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-5">
              <div className="mt-4">
                <p>
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="mt-4">
                <p>
                  <br />
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        {/* section image header */}
        <div className={styles.homeImage}>
          <div className={styles.content}>
            <h1>{selectedFormation.title}</h1>
          </div>
        </div>
        <div className={styles.container}>
          {/* Section Objectif */}
          <div className={`${styles.section}`}>
            <div className="row">
              <div className="col-md-8">
                <div className={styles.textBlock}>
                  <h2 className={styles.title}>Objectif de la formation</h2>
                  <p className={styles.paragraph}>{selectedFormation.description}</p>
                </div>
              </div>
              <div className="col-md-4">
                <img src={formation} alt="Président" className={styles.image} />
              </div>
            </div>
          </div>
        </div>

        {/* Modules de la formation */}

        <div className={`${styles.sections}`}>
          <h2 className={styles.title}>Modules de la formation</h2>
          <div className="row">
            {/* Colonne gauche pour les modules "M1" */}
            <div className="col-md-6">
              {selectedFormation.modules
                .filter((module) => module.title === 'M1')
                .map((module) => (
                  <div key={module.id} className={`card ${styles.moduleCard}`}>
                    <div className={styles.card_body}>
                      <h5 className={styles.card_title}>{module.title}</h5>
                      <p className={styles.card_text}>{module.description}</p>
                      <p className={styles.card_hours}>{module.hours} heures</p>
                    </div>
                  </div>
                ))}
            </div>

            {/* Colonne droite pour les modules "M2" */}
            <div className="col-md-6">
              {selectedFormation.modules
                .filter((module) => module.title === 'M2')
                .map((module) => (
                  <div key={module.id} className={`card bg-light ${styles.moduleCard}`}>
                    <div className={styles.card_body}>
                      <h5 className={styles.card_title}>{module.title}</h5>
                      <p className={styles.card_text}>{module.description}</p>
                      <p className={styles.card_hours}>{module.hours} heures</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Débouchés De La Formation */}

        <div className={`${styles.section3}`}>
          <h2 className={styles.title}>Débouchés De La Formation </h2>
          {/* Première colonne de cartes (blanche) */}
          <div className="row">
            <div className="col-md-6">
              {selectedFormation.unblock.map((unblock) => (
                <ul key={unblock.id} className={`${styles.unblockCard}`}>
                  <li className={styles.unblock_body}>
                    <VscVerifiedFilled /> <p className={styles.unblock_title}>{unblock.title}</p>
                  </li>
                </ul>
              ))}
            </div>
            <div className="col-md-6">
              <img className={styles.images} src={image} alt="" />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ModuleSmartComponent;
