/* eslint-disable */
import React from 'react';
import styles from './404.module.css';
import commingSoon from '../../assets/commingSoon.png';

function CommingSoon() {
  return (
    <div className={styles.commingSoon}>
      <img className={styles.srcCommingSoom} src={commingSoon} alt="comming-soon" />
    </div>
  );
}
export default CommingSoon;
