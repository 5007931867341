/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import Home from './views/home/home-view.component';
import About from './views/about/about-view.component';
import ModuleSmartComponent from './views/module/module-view.component';
import logo from './assets/logo/logo.svg';
import styles from './App.module.css';
import Footer from 'components/Footer/Footer.component';
import FormationsComponent from 'features/Formations/Formations-smart.component';
import SignUpComponent from 'features/SignUp/SignUp-smart.component';
import ContactComponent from 'features/Contact/Contact-smart.component';
import CommingSoon from 'components/404/404.component';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Router>
        <nav className={styles.social}>
          <FaFacebook />
          <FaTwitter />
          <FaInstagram />
        </nav>
        <div className={styles.headerDiv}>
          <header>
            <div className={styles.container}>
              <Link to="/" className={styles.logo}>
                <img src={logo} alt="Logo" />
              </Link>
              <Navigation />
            </div>
          </header>
        </div>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/licences" element={<FormationsComponent />} />
            <Route path="/module/:formationId" element={<ModuleSmartComponent />} />
            <Route path="/singup" element={<SignUpComponent />} />
            <Route path="/contact" element={<ContactComponent />} />
            <Route path="/comming-soon" element={<CommingSoon />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </>
  );
}

function Navigation() {
  const [showFormationsDropdown, setShowFormationsDropdown] = useState(false);
  const [showEspaceDropdown, setShowEspaceDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const formations = ['Management Et RH', 'Logistique', 'Commerce & Marketing', 'Informatique'];
  const espaces = ['Espace Stagiaire', 'Espace Entreprise', 'Espace Formateur'];

  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const toggleFormationsDropdown = () => {
    setShowFormationsDropdown(!showFormationsDropdown);
    setShowEspaceDropdown(false);
  };

  const toggleEspaceDropdown = () => {
    setShowEspaceDropdown(!showEspaceDropdown);
    setShowFormationsDropdown(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.hamburger} onClick={toggleMobileMenu}>
        &#9776;
      </div>
      <ul className={`${styles.menu} ${isMobileMenuOpen ? styles.open : ''}`}>
        <li className={activeLink === '/' ? styles.active : ''}>
          <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>
            Accueil
          </Link>
        </li>
        <li className={activeLink === '/about' ? styles.active : ''}>
          <Link to="/about" onClick={() => setIsMobileMenuOpen(false)}>
            À propos
          </Link>
        </li>
        <li className={activeLink === '/licences' ? styles.active : ''}>
          <Link to="/licences" onClick={() => setIsMobileMenuOpen(false)}>
            Licences
          </Link>
        </li>
        <li className={styles.hideOnMobile}>
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleEspaceDropdown();
            }}
          >
            Espaces
          </a>
          {showEspaceDropdown && (
            <ul>
              {espaces.map((espace) => (
                <li key={espace} className={activeLink === `/comming-soon` ? styles.active : ''}>
                  <a onClick={() => setIsMobileMenuOpen(false)} href="/comming-soon">
                    {espace}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li className={activeLink === '/contact' ? styles.active : ''}>
          <Link to="/contact" onClick={() => setIsMobileMenuOpen(false)}>
            Contactez-nous
          </Link>
        </li>
        <li className={activeLink === '/singup' ? styles.active : ''}>
          <Link to="/singup" onClick={() => setIsMobileMenuOpen(false)}>
            Inscription
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default App;
