/* eslint-disable */
import React from 'react';
import styles from './Footer.module.css';
import accreditation from '../../assets/logo-accreditation.png';
import logo from '../../assets/logo/logo.svg';
import googlePlayBadge from '../../assets/google.png';
import appStoreBadge from '../../assets/apple.png';

function Footer() {
  return (
    <>
      <div className={styles.pg_footer}>
        <footer className={styles.footer}>
          {/* <svg
            className={styles.footer_wave_svg}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className={styles.footer_wave_path}
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg> */}
          <div className={styles.footer_content}>
            <div className={styles.footer_content_column}>
              <div className={styles.footer_logo}>
                <a className={styles.footer_logo_link} href="#">
                  <span className={styles.hidden_link_text}></span>
                  <img src={logo} alt="Logo" className={styles.logo} />
                </a>
              </div>
              <div className={styles.footer_menu}>
                <ul id={styles.menu_get_started} className={styles.footer_menu_list}>
                  <li
                    className={`{${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_product}`}
                  >
                    <a href="#">
                      Le Groupe IPIRNET est une institution de formation dédiée à l'excellence
                      académique et professionnelle.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.footer_content_column}>
              <div className="row">
                <div className="col-md-4">
                  <div className={styles.footer_menu}>
                    <h2 className={styles.footer_menu_name}> Licence Professionnelle</h2>
                    <ul id={styles.menu_company} className={styles.footer_menu_list}>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/licences">Management et RH</a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/licences">Logistique</a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/licences">Commerce & Marketing</a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/licences">Informatique</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.footer_menu}>
                    <h2 className={styles.footer_menu_name}>Diplômes Accrédités</h2>
                    <ul id={styles.menu_company} className={styles.footer_menu_list}>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/module/Technicien-Spécialisé-en-Développement-Informatique">
                          Technicien Spécialisé en Développement Informatique
                        </a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/module/Technicien-Spécialisé-en-Gestion-des-Entreprises">
                          Technicien Spécialisé en Gestion des Entreprises
                        </a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/module/Technicien-en-Gestion-Informatisée">
                          Technicien en Gestion Informatisée
                        </a>
                      </li>
                      <li
                        className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                      >
                        <a href="/module/Opérateur-en-Audiovisuel-et-Digital">
                          Opérateur en Audiovisuel et Digital
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.footer_content_column}>
                    <div className={styles.footer_menu}>
                      <h2 className={styles.footer_menu_name}> Contactez-nous</h2>
                      <ul id={styles.menu_quick_links} className={styles.footer_menu_list}>
                        <li
                          className={`${styles.menu_item}
                    ${styles.menu_item_type_custom}
                    ${styles.menu_item_object_custom}`}
                        >
                          <a target="_blank" rel="noopener noreferrer" href="#">
                            ipirnet@gmail.com
                          </a>
                        </li>
                        <li
                          className={`${styles.menu_item}
                    ${styles.menu_item_type_custom}
                    ${styles.menu_item_object_custom}`}
                        >
                          <a target="_blank" rel="noopener noreferrer" href="#">
                            +212-522-327-213
                          </a>
                        </li>
                        <li
                          className={`${styles.menu_item}
                    ${styles.menu_item_type_post_type}
                    ${styles.menu_item_object_page}`}
                        >
                          <a href="#">
                            Lot Essafi, Imm N 1, 2eme Etage, Boulevard Hassan II, Berrchid 26100
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.footer_content_column}>
              <div className={styles.footer_call_to_action}>
                <h2 className={styles.footer_call_to_action_title}> Télécharger l'application </h2>
                <div className={styles.badgesContainer}>
                  <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                    <img
                      src={googlePlayBadge}
                      alt="Google Play Store"
                      className={styles.storeBadge}
                    />
                  </a>
                  <a
                    href="https://www.apple.com/ios/app-store/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStoreBadge} alt="App Store" className={styles.storeBadge} />
                  </a>
                </div>
                <img className={styles.accreditation} src={accreditation} alt="accreditation" />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
export default Footer;
